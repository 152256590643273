import React from "react"

import SEO from "../components/seo"
import { Container, Navbar, Col } from "react-bootstrap"
import Countdown from "react-countdown"
import { FaWhatsapp } from "react-icons/fa"

import loadable from "@loadable/component"

const SectioOne = loadable(() => import("../components/section/secOne"))
const SectionTwo = loadable(() => import("../components/section/secTwo"))
const SectionThree = loadable(() => import("../components/section/secTheree"))
const SectionFour = loadable(() => import("../components/section/secFour"))
const SectionFive = loadable(() => import("../components/section/secFive"))
const SectionSix = loadable(() => import("../components/section/secSix"))
const Guartee = loadable(() => import("../components/section/Guarte"))
const Faq = loadable(() => import("../components/section/Faq"))

class IndexPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SEO title="Sales Page" />
        <Navbar
          fixed="top"
          className="bg-navbar-mof"
          variant="light"
          expand="lg"
          bg="light"
        >
          <Container fluid>
            <Col lg={6} xs={12}>
              <div className="text-App">
                <p className="mb-0">Special Disc 52% Akan Berakhir Pada</p>
              </div>
            </Col>
            <Col lg={6} xs={12}>
              <div className="App">
                <Countdown date={"2023-10-31 23:59"} />
              </div>
            </Col>
          </Container>
        </Navbar>

        <Container className="background-SecOne margin-top-50-head" fluid>
          <SectioOne></SectioOne>
          <SectionTwo></SectionTwo>
          <SectionThree></SectionThree>
          <SectionFour></SectionFour>
          <SectionFive></SectionFive>
          <SectionSix></SectionSix>
          <Guartee></Guartee>
          <Faq></Faq>
          <a
            href="https://wa.link/6ov9gp"
            target="_blank"
            rel="noreferrer"
            className="float"
          >
            <div className="my-float">
              <FaWhatsapp /> &nbsp; Butuh Bantuan?
            </div>
          </a>
        </Container>
      </React.Fragment>
    )
  }
}
export default IndexPage
